import themePalette from "../../../../.brew-cache/theme.json";
import droplet from "../../../images/droplet.png";

export default theme => ({
    header: {
        display: 'block',
        position: 'relative',
        // Foreground colours
        '&[data-text-color="primary"]': { color: themePalette.ThemeOptions.mainColours.primaryColour},
        '&[data-text-color="secondary"]': { color: themePalette.ThemeOptions.mainColours.secondaryColour},
        '&[data-text-color="tertiary"]': { color: themePalette.ThemeOptions.mainColours.tertiaryColour},
        '&[data-text-color="foreground_primary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
        '&[data-text-color="foreground_secondary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
        '&[data-text-color="foreground_tertiary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
        '&[data-text-color="background_primary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
        '&[data-text-color="background_secondary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
        '&[data-text-color="background_tertiary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},
        // Background colours
        '&:not([data-bg-color="initial"])': {padding: 12},
        '&[data-bg-color="primary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.primaryColour},
        '&[data-bg-color="secondary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.secondaryColour},
        '&[data-bg-color="tertiary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.tertiaryColour},
        '&[data-bg-color="foreground_primary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
        '&[data-bg-color="foreground_secondary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
        '&[data-bg-color="foreground_tertiary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
        '&[data-bg-color="background_primary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
        '&[data-bg-color="background_secondary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
        '&[data-bg-color="background_tertiary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},

        '&.hero-text': {
            width: '100vw',
            maxWidth: '100vw',
            marginLeft: '50%',
            transform: 'translateX(calc(-50%))',
            fontSize: 98,
            lineHeight: '.84 !important',
            fontStyle: 'italic',
            letterSpacing: '-0.008em',
            color: 'white !important',
            textTransform: 'uppercase',
            textShadow: '-1px -1px 0 ' + theme.palette.primary.main + ', -1px 1px 0 ' + theme.palette.primary.main + ', 1px -1px 0 ' + theme.palette.primary.main + ', 1px 1px 0 ' + theme.palette.primary.main,
            [theme.breakpoints.up('md')]: { 
                fontSize: '148px !important',
                marginBottom: -40,
                transform: 'translateX(calc(-50% - 20px))',
            },
            [theme.breakpoints.up('lg')]: { fontSize: '172px !important' }
        },

        '&.droplet-bg': {
            backgroundImage: 'url(' + droplet + ')',fontSize: 32,
            fontStyle: 'italic',
            letterSpacing: '-0.008em',
            lineHeight: 1.5,
            fontWeight: '800',
            textAlign: 'center',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            margin: '0 auto -36px !important',
            maxWidth: 680,
            backgroundSize: 'contain',
            padding: '92px 0 186px',
            [theme.breakpoints.up('md')]: {
                fontSize: 52,
                lineHeight: 1.23,
                margin: '0 auto -55px !important',
                padding: '188px 0 400px',
            }
        },

        '&.after-line': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            lineHeight: 1,
            marginBottom: 32,
            '&::after': {
                flex: '1 0 auto',
                marginLeft: 24,
                content: '""',
                display: 'block',
                height: 1,
                backgroundColor: theme.palette.primary.main + '4D',
            }
        }
    },
    headerTheme: {
        ...theme.headerTheme,
    }
})